@import "../../../theme/theme";

.container {
    padding: .5rem 1rem;

    .header {
        margin-bottom: 2.5rem;
    }
    .optionsSection {
        padding: .5rem 2rem;
    }
    .confirmSection {
        margin-top: 2rem;
        background-color: #fff;
        padding: 1rem;
    }
}

.badge {
    visibility: hidden;

    &.visible {
        visibility: visible;
    }
}

.card {
    min-height: 35rem;
    transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
    background-color: rgba($color: #eee, $alpha: 0.4);
    border: 1px solid #ddd;

    &.selected {
        transform: scale(1.04);
        box-shadow: 0 4px 12px rgba(24, 144, 255, 0.3);
        background-color: #fff;
    }
    .title {
        margin: 0 auto;
        width: fit-content;
        position: relative;
        top: -4rem;
    }
    .description {
        margin: 0;
    }
    .alert {
        margin-top: 2rem;
    }
}

.remoteHomeCheckModal {
    .description {
        padding: .5rem 1rem;
        margin-top: 1rem;
        margin-bottom: 2rem;
    }
    .scheduleLaterBtn {
        background-color: #ddd;
        color: #333;

        &:hover {
            background-color: #ccc !important;
            color: #222 !important;
        }
    }
}
